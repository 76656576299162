import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { replace, goBack } from 'connected-react-router';
import { toast } from 'react-toastify';
import Helpers from 'utils/helpers';
import { productService } from 'services';
import { callApi } from 'providers/GeneralProvider/saga';
import i18n from 'i18n';
import { ISearch } from 'models/common';
import Loading from 'utils/loading';

import {
  // PLOP: Add slice import below -- DO NOT ERASE
  getProductDetailRequest,
  getProductDetailSuccess,
  getProductDetailFailure,
  getProductListRequest,
  getProductListSuccess,
  getProductListFailure,
  createProductRequest,
  createProductSuccess,
  createProductFailure,
  editProductRequest,
  editProductSuccess,
  editProductFailure,
  getProductListByUserRequest,
  getProductListByUserSuccess,
  getProductListByUserFailure,
  archiveProductRequest,
  archiveProductSuccess,
  archiveProductFailure,
  activeProductRequest,
  activeProductSuccess,
  activeProductFailure,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,
  reset,
} from './slice';
// PLOP: Add worker export below -- DO NOT ERASE
export function* getProductDetail(action: AnyAction): unknown {
  try {
    yield put(reset({}));
    Loading.show();
    const res = yield callApi(productService.getDetail, action.payload.id);
    Loading.hide();

    yield put(getProductDetailSuccess({ detail: res.data }));
  } catch (error) {
    Loading.hide();
    yield put(getProductDetailFailure({ error }));
  }
}
export function* getProductList(action: AnyAction): unknown {
  try {
    const params: ISearch = Helpers.convertParams(action.payload);
    const res = yield callApi(productService.getProducts, params);

    yield put(getProductListSuccess({ list: res.data, total: res.pagination.total }));
  } catch (error) {
    yield put(getProductListFailure({ error }));
  }
}
export function* getProductListByUser(action: AnyAction): unknown {
  try {
    const res = yield callApi(productService.getProductsByUser, action.payload);

    yield put(
      getProductListByUserSuccess({
        listProductByUser: res.data,
        // totalProductByUser: res.pagination.total,
      }),
    );
  } catch (error) {
    yield put(getProductListByUserFailure({ error }));
  }
}
export function* createProduct(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(productService.addProduct, action.payload);
    Loading.hide();

    if (res) {
      toast.success(i18n.t('message:created'));
      yield put(createProductSuccess(res));
      yield put(replace(`/products/${res.data}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(createProductFailure({ error }));
  }
}

export function* editProduct(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(productService.editProduct, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:updated'));
      yield put(editProductSuccess(res));
      // yield put(goBack());
      yield put(replace(`/products/${action.payload.id}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(editProductFailure({ error }));
  }
}

export function* archiveProduct(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(productService.archiveProduct, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:archived'));
      yield put(archiveProductSuccess(res));
      yield put(getProductDetailRequest({ id: action.payload }));
    }
  } catch (error) {
    Loading.hide();
    yield put(archiveProductFailure({ error }));
  }
}

export function* activeProduct(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(productService.unArchiveProduct, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:actived'));
      yield put(activeProductSuccess(res));
      yield put(getProductDetailRequest({ id: action.payload }));
    }
  } catch (error) {
    Loading.hide();
    yield put(activeProductFailure({ error }));
  }
}

export function* deleteProduct(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(productService.deleteProduct, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:deleted'));
      yield put(deleteProductSuccess(res));
      yield put(replace('/products'));
    }
  } catch (error) {
    Loading.hide();
    yield put(deleteProductFailure({ error }));
  }
}

export default function* productWatch(): Generator {
  // PLOP: Add fork below -- DO NOT ERASE
  yield takeEvery(getProductDetailRequest.type, getProductDetail);
  yield takeEvery(getProductListRequest.type, getProductList);
  yield takeEvery(getProductListByUserRequest.type, getProductListByUser);
  yield takeLeading(createProductRequest.type, createProduct);
  yield takeLeading(editProductRequest.type, editProduct);
  yield takeLeading(archiveProductRequest.type, archiveProduct);
  yield takeLeading(activeProductRequest.type, activeProduct);
  yield takeLeading(deleteProductRequest.type, deleteProduct);
}

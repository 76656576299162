const TRANSLATIONS_EN = {
  global: {
    yes: 'Yes',
    no: 'No',
  },
  login: {
    login: 'Log in',
  },
  menuBar: {
    dashboard: 'Dashboard',
    bookings: 'Bookings',
    sales: 'Sales',
    providerEarnings: 'Provider Earnings',
    providerPayout: 'Provider Payout',
    providers: 'Service Providers',
    customers: 'Customers',
    services: 'Services',
    support: 'Support',
    userReport: 'User report',
    systemUsers: 'System users',
    users: 'Users',
    roles: 'Roles',
    activityLogs: 'Activity logs',
    settings: 'Settings',
    products: 'Products',
  },
  actionButton: {
    addCoach: 'Add Coach',
    uploadAndContinue: 'UPLOAD AND CONTINUE',
    addFile: 'ADD FILE',
    createAccounts: 'CREATE ACCOUNTS',
    replaceFile: 'Replace file',
    cancel: 'Cancel',
    submit: 'Submit',
    ok: 'OK',
    createAccount: 'CREATE ACCOUNT',
    changePassword: 'Change password',
    change: 'CHANGE',
    deactivate: 'DEACTIVATE',
    active: 'ACTIVE',
    delete: 'DELETE',
    prev: 'PREV',
    next: 'NEXT',
    showToday: 'Show Today',
    showThisWeek: 'Show this week',
    showThisMonth: 'Show this month',
    showThisCycle: 'Show this cycle',
    view: 'View',
    invite: 'Invite',
    add: 'Add',
  },
  validation: {
    invalidEmail: 'this must be a valid email',
  },
  tableForm: {
    search: 'Search',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select',
    exportResultCSV: 'Export results (CSV)',
  },
  alert: {
    clear: 'Clear',
    sessionExpired: 'Session expired',
    loginRequired: 'Your session has expired. Please log in again',
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
  button: {
    goBack: 'Go Back',
  },
  loading: {
    loading: 'Loading',
  },
  profile: {
    fullName: 'Full name',
    accountStatus: 'Account Status',
    lastLogin: 'Last login',
    phoneticName: 'Phonetic Name',
    email: 'Email',
    emailAddress: 'E-mail address',
    birthday: 'Birthday',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    lastName: 'Last name',
    firstName: 'First name',
    phoneticLastName: 'Phonetic last name',
    phoneticFirstName: 'Phonetic first name',
    displayName: 'Display name',
    profilePicture: 'Profile picture',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    unanswered: 'Unanswered',
    registerDate: 'Register date',
    region: 'Region',
    prefecture: 'Prefecture',
    zipCode: 'Zip code',
    businessImages: 'Business images',
    profileText: 'Profile text',
    businessName: 'Business name',
    businessNumber: 'Business number',
    addressLine1: 'Address line 1',
    municipality: 'Municipality',
    addressLine2: 'Address line 2',
    addressPrivacySettings: 'Address privacy settings',
    addressPrivacy: 'Address privacy',
    mapLocation: 'Map location',
    accountHolder: 'Account holder',
    accountNumber: 'Account number',
    accountType: 'Account type',
    bankBookImage: 'Bank book image',
    financialInstitution: 'Financial institution',
    financialInstitutionName: 'Financial institution name',
    bankCode: 'Bank code',
    branchName: 'Branch name',
    branchCode: 'Branch code',
    username: 'Username',
    password: 'Password',
    role: 'Role',
    newPassword: 'New password',
    permissionBooking: 'Bookings',
    permissionProvider: 'Provider management',
    permissionCustomer: 'Customer management',
    permissionService: 'Service management',
    description: 'Description',
  },
  detail: {
    detail: 'Detail',
    paymentType: 'Payment type',
    paymentID: 'Payment ID',
    customerName: 'Customer name',
    totalChargedToCard: 'Total charged to card',
    cardEndingIn: 'Card ending in',
    chargedOn: 'Charged on',
    menuItem: 'Menu item',
    amount: 'Amount',
    totalAmount: 'Total amount',
    subtotal: 'Subtotal',
    discount: 'Discount',
    lastUpdated: 'Last updated',
    description: 'Description',
    servicePhoto: 'Service photo',
    displayOrder: 'Display order',
  },
  common: {
    tags: 'Tags',
    disable: 'Disable',
    enable: 'Enable',
    confirm: 'Confirm',
    ruSure: 'Are you sure?',
    create: 'Create',
    createNewAccount: 'Create new account',
    optional: 'Optional',
    upload: 'Upload',
    createSystemAccount: 'Create system account',
    editSystemAccount: 'Edit system account',
    createRole: 'Create Role',
    delete: 'Delete',
    createProduct: 'Create Product',
    editProduct: 'Edit Product',
    secretKey: 'Secret key',
    createdAt: 'Created at',
    edit: 'Edit',
    information: 'Information',
    product: 'Product',
    role: 'Role',
    permission: 'Permission',
    register: 'Register',
    logout: 'Logout',
    notPer: "Sorry! You don't have permission",
    notFound: 'Sorry! The page not found',
    logoutText: 'Logout',
  },
  action: {
    changeStatus: 'Change status',
    cancel: 'Cancel',
    duplicate: 'Duplicate',
    viewDetails: 'View Details',
  },
  status: {
    pending: 'Pending',
    confirmed: 'Confirmed',
    cancelled: 'Cancelled',
    complete: 'Complete',
    deleted: 'Deleted',
    active: 'Active',
    disabled: 'Disabled',
  },
  title: {
    serviceProviders: 'Service providers',
    profile: 'Profile',
    booking: 'Booking',
    services: 'Services',
    schedule: 'Schedule',
    sales: 'Sales',
    reviews: 'Reviews',
    basicInformation: 'Basic information',
    businessInformation: 'Business information',
    bankInformation: 'Bank information',
    customers: 'Customers',
    genderatedContent: 'Genderated content',
    bookingInformation: 'Booking information',
    paymentInformation: 'Payment information',
    statusHistory: 'Status history',
    requested: 'Requested',
    confirmation: 'Confirmation',
    createService: 'Create service',
    editCategory: 'Edit category',
    usernameAndPassword: 'Username & password',
    systemAccount: 'System accounts',
    role: 'Role',
    changePassword: 'Change password',
    deactivate: 'Deactivate',
    active: 'Active',
    delete: 'Delete',
    nameAndDescription: 'Name and description',
    permission: 'Permissions',
    earnings: 'Earnings',
    currentPayoutCycleEarnings: 'Earnings of current payout cycle',
    historyEarnings: 'History earnings',
    earningDetail: 'Earning details',
    transactions: 'Transactions',
    products: 'Products',
  },
  description: {
    statusHistoryRequested: 'Customer has requested booking',
    statusHistoryConfirmation: 'Waiting for provider confirmation',
    statusHistoryComplete: 'Customer received service',
    changePassword: 'Change password of this account.',
    deactivateAccount: 'The account will be deactivate and user will no longer have access.',
    activeAccount: 'The account will be active and user can access.',
    deleteAccount: 'The account will be deleted.',
    activeRole: 'The role will be active and user can access.',
    deactivateRole: 'The role will be deactivate and user will no longer have access.',
    deleteRole: 'The role will be deleted.',
  },
  tableColumn: {
    view: 'View',
    edit: 'Edit',
    active: 'Active',
    deactive: 'Deactive',
    delete: 'Delete',
    archive: 'Archive',
    unArchive: 'UnArchive',
  },
  columnTitle: {
    numberId: 'ID #',
    avatar: 'Avatar',
    fullName: 'Full name',
    email: 'Email',
    phoneNumber: 'Phone number',
    registerDate: 'Register date',
    status: 'Status',
    action: 'Action',
    bookingId: 'Booking ID',
    lastUpdate: 'Last update',
    bookingDate: 'Booking date',
    provider: 'Provider',
    customer: 'Customer',
    total: 'Total',
    categoryName: 'Category name',
    serviceName: 'Service name',
    duration: 'Duration',
    price: 'Price',
    category: 'Category',
    tags: 'Tags',
    providerName: 'Provider',
    datePayout: 'Date',
    fromDate: 'From date',
    toDate: 'To date',
    totalGross: 'Total gross',
    totalFee: 'Total fee',
    totalNet: 'Total net',
    name: 'Name',
    role: 'Role',
    description: 'Description',
    activityTime: 'Time',
    message: 'Message',
    triggeredBy: 'Triggered by',
    earningGross: 'Earnings gross',
    earningNet: 'Earnings net',
    from: 'From',
    to: 'To',
    date: 'Date',
    transactionId: 'Transaction ID',
    transactionDate: 'Date',
    amount: 'Amount',
    amountNet: 'Amount net',
  },
  extraContent: {
    importCSV: 'Import (CSV)',
    exportAllCSV: 'Export all (CSV)',
    inviteUser: 'Create user',
    createBooking: 'Create booking',
    createCategory: 'Create category',
    addService: 'Add service',
    createRole: 'Create role',
    createProduct: 'Create Product',
  },
  placeholder: {
    searchDefault: 'Search user name, ID, registerd email',
    searchBooking: 'Search Booking ID, Customer, Provider',
    searchService: 'Search service name',
    searchProviderEarning: 'Search by provider email, ID, user name, phone',
    searchPayout: 'Search Payout By Id, Name',
    searchProduct: 'Search Product Name',
    searchKeyword: 'Search by keyword',
  },
  type: {
    protect: 'Protect',
    public: 'Public',
    saving: 'Saving',
    standard: 'Standard',
  },
  importCSVModal: {
    title: 'Create provider via import CSV',
    download: 'Download a',
    sampleCSVTemplate: 'sample CSV template',
    seeExample: 'to see example.',
    dragDropCSV: 'Drag drop CSV file here',
    overwriteDuplicateEmail: 'Overwrite any accounts with duplicate email address.',
    addedFile: 'Added file',
    importedCSVNotification: `Creating multiple provider accounts, please preview to make sure contents look right, 
      If not you can adjust here.`,
    youWillImport: 'You will be importing',
    numberAccount: 'accounts',
    withTotal: 'with total of etc etc copy text about file details goes here.',
    importingDataNotificationTitle: 'Current importing data to provider lists in database',
    importingDataNotificationContent: `This could task some time to complete. If you'd like, 
    you can close this dialog box and continue working.`,
    closeImportCSVTitle: 'Close Import CSV popup?',
    closeImportCSVContent: 'Do you want to cancel the import CSV.',
  },
  role: {
    administrator: 'Administrator',
    custom: 'Custom',
    provider: 'Provider',
    name: 'Name',
    code: 'Code',
    permissions: 'Permissions',
    level: 'Level',
    levelDes: 'Level - High priority for smaller numbers',
    editRole: 'Edit role',
    root: 'Root',
    actions: 'Actions',
    note: 'Note',
    noteContent: 'Level - High priority for smaller numbers',
    select: 'Search and select role',
    selectOnly: 'Select role',
    assignRole: 'Assign Role',
    rootRole: 'Root role',
  },
  providerConfirmModal: {
    disabled: 'Do you want to disable this provider account?',
    active: 'Do you want to enable this provider account?',
    delete: 'Do you want to delete this provider account?',
  },
  customerConfirmModal: {
    disabled: 'Do you want to disable this customer account?',
    active: 'Do you want to enable this customer account?',
    delete: 'Do you want to delete this customer account?',
  },
  dateViewMode: {
    today: 'Today',
    thisWeek: 'This week',
    thisMonth: 'This month',
    thisCycle: 'This cycle',
    customDate: 'Custom date',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    payoutCycle: 'Payout cycle',
    custom: 'Custom',
  },
  changePasswordModal: {
    title: 'Reset your password?',
    content: 'Do you want to reset your password? We will send reset password link to your email.',
    contentForUser:
      "Do you want to reset this user's password? We will send reset password link to this user's email.",
  },
  payout: {
    payout: 'Payout',
    payoutDate: 'Payout date',
    earning: 'Earning',
    commissionFee: 'Commission fee',
    transferFee: 'Transfer fee',
    transferTotal: 'Transfer total',
    providerAddress: 'Provider address',
    staffNumber: 'Staff number',
    chargedBooking: 'Charged booking',
    bankName: 'Bank name',
    bankNumber: 'Bank number',
    bankUserName: "Bank's user name",
    staff: 'Staff',
    chargedDate: 'Charged date',
  },
  service: {
    services: 'Services',
    addService: 'Add service',
  },
  notification: {
    notificationTitleText: 'NOTIFICATIONS',
    notificationMarkReadText: 'Mark All as Read',
    notificationLoadedText: 'Yay! You have seen it all',
    notificationLoadingText: 'Loading...',
    notificationNoDataText: 'No Data',
  },
  product: {
    products: 'Products',
    product: 'Product',
    productName: 'Name',
    productNameJa: 'Product name JP',
    adminSiteUrl: 'Admin site url',
    adminSiteUrlCallBack: 'Admin site url callback',
    productId: 'Product id',
    authen: 'Authentication',
    productCode: 'Code',
    domain: 'Domain',
    clientId: 'Client id',
    appId: 'App id',
    apiId: 'Api id',
    tokenSecretKey: 'Token secret key',
    audience: 'Audience',
    logo: 'Logo',
    logoUrl: 'Logo URL',
    tokenExpire: 'Token expire (seconds)',
    refreshTokenExpire: 'Refresh token expire (seconds)',
    tokenExpireNotSecond: 'Token expire',
    refreshTokenExpireNotSecond: 'Refresh token expire',
    loginUrlCallback: 'Login callback URL',
    logoutCallbackUrl: 'Logout callback URL',
    auth0: 'Auth0',
    select: 'Search and select product',
    seconds: '(seconds)',
    integrationExp: 'Integration Code Examples',
    auth0Domain: 'Auth0 Domain',
    auth0ManagementClientId: 'Auth0 Management Client Id',
    auth0ManagementSecretKey: 'Auth0 Management Secret Key',
    auth0ManagementAudience: 'Auth0 Management Audience',
  },
  user: {
    users: 'Users',
    invite: 'Invite',
    inviteUser: 'Invite User',
  },
  message: {
    created: 'Successfully created!',
    updated: 'Successfully updated!',
    deleted: 'Successfully deleted!',
    invited: 'Successfully invited!',
    assigned: 'Successfully assigned!',
    unAssigned: 'Successfully Unassigned!',
    verify: 'Verification Successful!',
    verifyExist: 'User already exists in the system',
    verifyInvite: 'Verify user invitation',
    required: 'this is a required field',
    validateDomain: 'this must be a valid domain',
    validateUrl: 'this must be a valid URL',
    maxPrefix: 'this must be at most',
    character: 'characters',
    typeNumber: 'this must be a number',
    deleteTitle: 'Delete this item',
    deleteDes: 'Do you want to delete this item?',
    registerSuccess: 'You have registered successfully',
    archived: 'Successfully archived!',
    actived: 'Successfully actived!',
    areYouSure: 'Are you sure?',
    areYouSureDes: 'Are you sure you want to do this?',
  },
};

export default TRANSLATIONS_EN;

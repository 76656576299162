import { createSlice } from '@reduxjs/toolkit';
import { IProductDetail } from 'models/product';

export interface ProductState {
  // PLOP: Add state interface below -- DO NOT ERASE
  detail: IProductDetail | null;
  loading: boolean;
  archiveProductLoading: boolean;
  activeProductLoading: boolean;
  list: unknown[];
  total: number;
  loadingProductByUser: boolean;
  listProductByUser: unknown[];
  totalProductByUser: number;
}

const initialState: ProductState = {
  // PLOP: Add initial state below -- DO NOT ERASE
  detail: null,
  loading: false,
  archiveProductLoading: false,
  activeProductLoading: false,
  list: [],
  total: 0,
  loadingProductByUser: false,
  listProductByUser: [],
  totalProductByUser: 0,
};

const productState = createSlice({
  name: 'product',
  initialState,
  reducers: {
    reset(state, action) {
      return { ...state, ...initialState, loading: true };
    },
    // PLOP: Add reducers below -- DO NOT ERASE
    getProductDetailRequest(state, action) {
      return { ...state, loading: true };
    },
    getProductDetailSuccess(state, action) {
      const { detail } = action.payload;

      return { ...state, loading: false, detail };
    },
    getProductDetailFailure(state, action) {
      return { ...state, loading: false };
    },
    getProductListRequest(state, action) {
      return { ...state, loading: true };
    },
    getProductListSuccess(state, action) {
      const { list, total } = action.payload;

      return { ...state, loading: false, list, total };
    },
    getProductListFailure(state, action) {
      return { ...state, loading: false };
    },
    getProductListByUserRequest(state, action) {
      return { ...state, loadingProductByUser: true };
    },
    getProductListByUserSuccess(state, action) {
      const { listProductByUser, totalProductByUser } = action.payload;

      return {
        ...state,
        loadingProductByUser: false,
        listProductByUser,
        totalProductByUser,
      };
    },
    getProductListByUserFailure(state, action) {
      return { ...state, loadingProductByUser: false };
    },
    createProductRequest(state, action) {
      return { ...state, loading: true };
    },
    createProductSuccess(state, action) {
      return { ...state, loading: false };
    },
    createProductFailure(state, action) {
      return { ...state, loading: false };
    },

    editProductRequest(state, action) {
      return { ...state, loading: true };
    },
    editProductSuccess(state, action) {
      return { ...state, loading: false };
    },
    editProductFailure(state, action) {
      return { ...state, loading: false };
    },

    archiveProductRequest(state, action) {
      return { ...state, archiveProductLoading: true };
    },
    archiveProductSuccess(state, action) {
      return { ...state, archiveProductLoading: false };
    },
    archiveProductFailure(state, action) {
      return { ...state, archiveProductLoading: false };
    },

    activeProductRequest(state, action) {
      return { ...state, activeProductLoading: true };
    },
    activeProductSuccess(state, action) {
      return { ...state, activeProductLoading: false };
    },
    activeProductFailure(state, action) {
      return { ...state, activeProductLoading: false };
    },

    deleteProductRequest(state, action) {
      return { ...state, loading: true };
    },
    deleteProductSuccess(state, action) {
      return { ...state, loading: false };
    },
    deleteProductFailure(state, action) {
      return { ...state, loading: false };
    },
  },
});

export const {
  // PLOP: Add actions export below -- DO NOT ERASE
  getProductDetailRequest,
  getProductDetailSuccess,
  getProductDetailFailure,
  getProductListRequest,
  getProductListSuccess,
  getProductListFailure,
  getProductListByUserRequest,
  getProductListByUserSuccess,
  getProductListByUserFailure,
  createProductRequest,
  createProductSuccess,
  createProductFailure,
  editProductRequest,
  editProductSuccess,
  editProductFailure,
  archiveProductRequest,
  archiveProductSuccess,
  archiveProductFailure,
  activeProductRequest,
  activeProductSuccess,
  activeProductFailure,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,
  reset,
} = productState.actions;

export default productState.reducer;

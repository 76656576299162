import { AxiosResponse } from 'axios';
import { ISearch } from 'models/common';
import { ICreateProduct } from 'models/product';
import apiInstance from '../utils/api';

export default class ProductService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  getProducts = (params: ISearch): Promise<AxiosResponse> => {
    return this.get('', { params });
  };

  getProductsByUser = (userId: string): Promise<AxiosResponse> => {
    return this.get(`/user/${userId}`);
  };

  getDetail = (id: string): Promise<AxiosResponse> => {
    return this.get(`/${id}`);
  };

  addProduct = (payload: ICreateProduct): Promise<AxiosResponse> => {
    return this.post(`/add-product`, payload);
  };

  editProduct = (payload: ICreateProduct): Promise<AxiosResponse> => {
    return this.patch(`/${payload.id}`, payload);
  };

  archiveProduct = (id: string): Promise<AxiosResponse> => {
    return this.patch(`/${id}/archive`, null);
  };

  unArchiveProduct = (id: string): Promise<AxiosResponse> => {
    return this.patch(`/${id}/unarchive`, null);
  };

  deleteProduct = (id: string): Promise<AxiosResponse> => {
    return this.delete(`/${id}`);
  };
}
